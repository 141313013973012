import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const JoinWrapper = styled.div`
  /* margin-bottom: 1em; */

  &:last-of-type {
    margin-bottom: 1em;
  }

  h5 {
    color: #fff;
    margin-bottom: 3px;
    padding-left: 3px;
    text-shadow: 1px 1px 1px #222;
  }

  .joins {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .join {
    text-shadow: 1px 1px 1px #222;
    padding: 3px 10px;
    background: ${(props) =>
      props.secondary ? "var(--secondaryColor)" : "var(--primaryColorDarker)"};
    border-radius: 12px;
    font-size: 0.9em;
    color: #fff;
    font-weight: 600;
    border: 4px solid
      ${(props) =>
        props.secondary ? "var(--secondaryColor)" : "var(--primaryColor)"};
    margin: 2px;

    + .join {
      /* margin-left: 5px; */
    }
  }
`;

const JoinSection = ({ title, secondary, joins }) => (
  <JoinWrapper secondary={secondary}>
    <h5>{title}</h5>
    <div className="joins">
      {joins.map((j) => {
        if (j.url) {
          return (
            <Link to={j.url} key={j.id} className="join">
              {j.text}
            </Link>
          );
        }

        return (
          <div key={j.id} className="join">
            {j.text}
          </div>
        );
      })}
    </div>
  </JoinWrapper>
);

JoinSection.defaultProps = {
  secondary: false,
  title: "",
  joins: [],
};

export default JoinSection;
