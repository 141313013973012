import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";

import {
  BasicGrid,
  Button,
  EmptyList,
  Card,
  Icon,
  JoinLabels,
} from "@components/shared";

import { bcvaFormatted } from "@components/Procedure/procedureOptions";
import { PostOpCommit } from "./";
import { getUuid } from "@util/getUuid";

export default function PostOpList({
  items,
  fetchDetailData,
  showLink,
  reqData,
}) {
  const [loading, setLoading] = useState(false);
  const [visits, setVisits] = useState([]);

  useEffect(() => {
    setVisits(items);
  }, [JSON.stringify(items)]);

  useEffect(() => {
    fetchVisits();
  }, [JSON.stringify(reqData)]);

  function fetchVisits() {
    if (!reqData) return;

    const req = { ...reqData };

    setLoading(true);

    axios
      .post(`/retrieve-post-op-visits/`, req)
      .then(({ data }) => {
        setLoading(false);
        setVisits(data.response);
      })
      .catch((err) => {
        setVisits([]);
        setLoading(false);
      });
  }

  function commitVisit(visitId) {
    axios
      .post(`/post-op-visits/${visitId}/commit/`, {
        post_op_visit_id: visitId,
      })
      .then(() => {
        toast.success("Committed!");
        if (fetchDetailData) {
          fetchDetailData();
        } else {
          fetchVisits();
        }
      })
      .catch((err) => {
        toast.error(err);
        fetchVisits();
      });
  }

  return (
    <React.Fragment>
      {loading && <p>Fetching visits...</p>}
      {!loading && visits.length === 0 && items.length === 0 && (
        <EmptyList text="No visits yet..." />
      )}
      <BasicGrid columns={1}>
        {visits
          .sort((a, b) => a.variety - b.variety)
          .map((v) => (
            <Card.Wrapper key={v.id}>
              <Card.Meta
                secondary
                hasActions={true}
                canCommit={v.can_commit}
                isCommitted={v.committed_to_registry}
                missingFields={v.missing_fields}
                isProcedure
                title={`${v.variety_formatted}${
                  v.post_op_date_formatted
                    ? ` (${v.post_op_date_formatted})`
                    : ""
                }`}
                stats={[
                  { title: "IOP", value: v.iop },
                  {
                    title: "BCVA",
                    value: bcvaFormatted(v.bcva, v.bcva_method),
                  },
                  { title: "Discomfort", value: v.patient_discomfort },
                ].filter((f) => f.value)}
              />
              {v.join_names && v.join_names.length > 0 && (
                <Card.Content
                  secondary
                  rounded={
                    showLink || (v.can_commit && !v.committed_to_registry)
                      ? false
                      : true
                  }
                >
                  <BasicGrid columns={2}>
                    {v.join_names
                      .filter((f) => f.joins.length)
                      .map((m, i) => (
                        <JoinLabels
                          key={getUuid()}
                          title={m.title}
                          secondary
                          joins={m.joins.map((mm) => ({
                            text: mm.text,
                            id: getUuid(),
                          }))}
                        />
                      ))}
                  </BasicGrid>
                </Card.Content>
              )}
              {((showLink && !fetchDetailData) ||
                (v.can_commit && !v.committed_to_registry)) && (
                <Card.Actions secondary>
                  {v.can_commit && !v.committed_to_registry && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "fit-content",
                        margin: "10px auto",
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <PostOpCommit
                          item={v}
                          onSubmit={() => commitVisit(v.id)}
                        />
                      </div>
                    </div>
                  )}
                  {showLink && !fetchDetailData && (
                    <Button.Link
                      to={`/post-op-visits/${v.id}`}
                      text={<Icon.Eye size={27} />}
                    />
                  )}
                </Card.Actions>
              )}
            </Card.Wrapper>
          ))}
      </BasicGrid>
    </React.Fragment>
  );
}

PostOpList.defaultProps = {
  items: [],
  reqData: null,
  showLink: true,
};
