import React, { useState, useEffect, useRef } from "react";
import { Modal, Checkbox } from "semantic-ui-react";

import { Button } from "@components/shared";

const CommitButton = ({ onSubmit, item }) => {
  const [isOpen, setOpen] = useState(false);

  function onClose() {
    setOpen(false);
  }

  // const checks = [
  //   {
  //     label: "ocular & systemic comorbidities",
  //     count: item.procedure_comorbidities
  //       ? item.procedure_comorbidities.length
  //       : 0,
  //   },
  //   {
  //     label: "previous ocular surgeries",
  //     count: item.pos_procedures ? item.pos_procedures.length : 0,
  //   },
  //   {
  //     label: "ocular medications",
  //     count: item.join_names
  //       ? item.join_names.find((f) => f.title === "Medications")
  //         ? item.join_names.find((f) => f.title === "Medications").joins.length
  //         : 0
  //       : 0,
  //   },
  // ].filter((f) => f.count === 0);

  return (
    <div>
      <Button.Basic
        text={"Commit to registry"}
        onClick={() => setOpen(true)}
        primary
        style={{
          color: "#fff",
          fontWeight: "bold",
          border: "none",
          // margin: "1em auto",
          background: "var(--secondaryColor)",
        }}
      />
      <Modal open={isOpen} onClose={onClose}>
        <Modal.Content>
          {/* {checks.length > 0 && (
            <div>
              <p>
                Before you commit, please confirm the following are correct:
              </p>
              {checks.map((m, i) => (
                <div style={{ marginBottom: "5px" }} key={i}>
                  <Checkbox
                    label={
                      <label>
                        {m.count === 0 ? (
                          <span>This patient has no {m.label}</span>
                        ) : (
                          <span>
                            {m.label} ({m.count})
                          </span>
                        )}
                      </label>
                    }
                  />
                </div>
              ))}
            </div>
          )} */}
          <p style={{ marginTop: "1em" }}>
            Are you sure you want to commit this Post-Op Visit to the registry?
          </p>
        </Modal.Content>
        <Modal.Actions>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Button.Basic
              text="Commit Post-Op Visit"
              onClick={() => {
                onSubmit();
                onClose();
              }}
              primary
            />
            <Button.Basic text="Cancel" onClick={() => onClose()} />
          </div>
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default CommitButton;
