import React, { useState, useEffect } from "react";
import { Modal } from "semantic-ui-react";
import axios from "axios";
import toast from "react-hot-toast";

import { Button } from "@components/shared";
import { ProcedureJoinForm } from "./";

export default function ProcedureJoinEdit({ postOpId, onUpdate, editData }) {
  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const joinInfo = getJoinInfo(editData, postOpId);

  function onClose() {
    setOpen(false);
    onUpdate();
    setLoading(false);
  }

  function onSaveClick(editedObj) {
    if (!joinInfo) return alert("Something went terribly wrong...");

    const req = {
      ...editedObj,
      id: editData.join_id,
      entity: joinInfo.entity,
    };

    axios
      .post(`/update-join/`, req)
      .then(() => {
        onClose();
        toast.success("Updated!");
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <Modal onClose={onClose} open>
      <Modal.Content>
        <ProcedureJoinForm
          isMeds={editData.medication_id}
          editData={editData}
          editing
          onAddClick={onSaveClick}
        />
      </Modal.Content>
      <Modal.Actions>
        <div style={{ display: "flex" }}>
          {joinInfo && joinInfo.entity && (
            <DeleteButton
              entityName={joinInfo.entity}
              joinId={editData.join_id}
              onSuccess={() => {
                onClose();
              }}
            />
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              flexGrow: "1",
            }}
          >
            <Button.Basic text="Close" onClick={onClose} disabled={loading} />
          </div>
        </div>
      </Modal.Actions>
    </Modal>
  );
}

const DeleteButton = ({ entityName, joinId, onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [confirm, setConfirm] = useState(false);

  function onClick() {
    setLoading(true);

    const req = {
      id: joinId,
      entity: entityName,
    };

    axios
      .post(`/option-status/`, req)
      .then(() => {
        setLoading(false);
        toast.success("Deleted!");
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <div>
      {confirm ? (
        <div style={{ display: "flex", gap: "10px" }}>
          <Button.Basic
            primary
            text="I'm sure"
            onClick={onClick}
            loading={loading}
            disabled={loading}
          />
          <Button.Basic text="Cancel" onClick={() => setConfirm(false)} />
        </div>
      ) : (
        <Button.Basic text="Delete" onClick={() => setConfirm(true)} />
      )}
    </div>
  );
};

function getJoinInfo(opt, postOpId) {
  if (opt.cataract_status_id)
    return { entity: postOpId ? "CataractPostOpVisit" : "CataractProcedure" };
  if (opt.complication_id) return { entity: "PostOpComplication" };
  if (opt.sle_finding_id)
    return { entity: postOpId ? "SlePostOpVisit" : "SleProcedure" };
  if (opt.gonioscopy_id) return { entity: "GonioscopyProcedure" };
  if (opt.retinal_finding_id)
    return { entity: postOpId ? "RfPostOpVisit" : "RfProcedure" };
  if (opt.medication_id)
    return { entity: postOpId ? "PostOpMedication" : "MedProcedure" };
  if (opt.comorbidity_id) return { entity: "ProcedureComorbidity" };
  if (opt.glaucoma_diagnosis_id) return { entity: "DiagnosisProcedure" };
  if (opt.previous_ocular_surgery_id) return { entity: "PosProcedure" };

  return null;
}
