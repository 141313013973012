import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form, Modal } from "semantic-ui-react";
import toast from "react-hot-toast";

import { Button, DateField, Icon } from "@components/shared";
import { ComplicationForm } from "./ComplicationManagement";

export default function ComplicationEdit({
  minDate,
  joinId,
  procedureId,
  postopId,
  fetchData,
  editData,
}) {
  const [isOpen, setOpen] = useState(false);

  function onClose() {
    setOpen(false);
  }

  return (
    <div>
      <Button.Basic primary onClick={() => setOpen(true)} text="Edit" />
      <Modal open={isOpen} onClose={onClose} size="small">
        <Modal.Content>
          <ComplicationForm
            editData={editData}
            reqData={{
              procedure_id: procedureId,
              post_op_visit_id: postopId,
            }}
            onSuccess={() => {
              onClose();
              fetchData();
            }}
            updateRoute={
              procedureId
                ? `/update-procedure-complication/`
                : `/update-post-op-complication/`
            }
          />
        </Modal.Content>
      </Modal>
    </div>
  );
}

ComplicationEdit.defaultProps = {
  minDate: null,
};
