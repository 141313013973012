import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { useSelector, connect } from "react-redux";
import * as Yup from "yup";
import { formatPhoneNumberIntl } from "react-phone-number-input";

import { BasicForm } from "@components/shared";

import { statusOptions } from "./status";

import { getRoleInfo } from "@util/roleInfo";

function UserForm({
  email,
  firstName,
  id,
  isProfile,
  lastName,
  mfa,
  practiceId,
  prefix,
  status,
  suffix,
  userId,
  userPracticeId,
  onSuccess,
  dispatch,
}) {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const roleInfo = getRoleInfo(useSelector((state) => state));

  function onSubmit(formData) {
    if (id) return onUpdate(formData);

    return onCreate(formData);
  }

  function onCreate(formData) {
    const req = {
      ...formData,
      status: 1,
    };

    setLoading(true);
    setError(null);

    axios
      .post(`/users/`, req)
      .then(({ data }) => {
        createPhone(data.response[0].id, formData.phone);
        return data.response[0].id;
      })
      .then((newId) => {
        if (practiceId) {
          addToPractice(newId);
        } else {
          window.location = `/users/${newId}/practices`;
        }
      })
      // .then(() => onSuccess())
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }

  function createPhone(userId, phoneNumber) {
    const req = {
      user_id: userId,
      number: formatPhoneNumberIntl(phoneNumber),
    };

    axios.post(`/phones/`, req);
  }

  function onUpdate(formData) {
    const req = {
      ...formData,
    };

    setLoading(true);
    setError(null);

    axios
      .put(`/users/${id}/`, req)
      .then(() => setLoading(false))
      .then(() => toast.success("Updated!"))
      .then(() => {
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }

  function addToPractice(newUserId) {
    const req = {
      user_id: newUserId,
      practice_id: practiceId,
    };
    axios
      .post(`/users/${newUserId}/practices/`, req)
      .then(() => {
        window.location = `/users/${newUserId}/practices`;
      })
      .catch((err) => {
        setLoading(false);
        alert("User created, but not added to practice");
      });
  }

  const fields = [
    {
      name: "first_name",
      initialValue: firstName,
      label: "First Name",
      required: true,
      schema: () =>
        Yup.string()
          .min(2, "Too Short!")
          .max(140, "Too Long!")
          .required("Required"),
    },
    {
      name: "last_name",
      initialValue: lastName,
      label: "Last Name",
      required: true,
      schema: () =>
        Yup.string()
          .min(2, "Too Short!")
          .max(140, "Too Long!")
          .required("Required"),
    },
    {
      grouped: true,
      fields: [
        {
          name: "prefix",
          initialValue: prefix,
          label: "Prefix",
          required: false,
          schema: () => Yup.string().min(2, "Too Short!"),
        },
        {
          name: "suffix",
          initialValue: suffix,
          label: "Suffix",
          required: false,
          schema: () => Yup.string().min(2, "Too Short!"),
        },
      ].map((m) => ({
        ...m,
        readOnly: id && roleInfo.admin_research_fellow ? true : false,
      })),
    },
    {
      name: "email",
      initialValue: email,
      label: "Email",
      required: true,
      schema: () =>
        Yup.string().email("Not a valid email").required("Required"),
    },
    {
      name: "phone",
      initialValue: "",
      label: "Phone",
      required: true,
      phone: true,
      schema: () => Yup.string().min(2, "Too Short!").required("Required"),
    },
    {
      name: "status",
      initialValue: status,
      label: "Status",
      select: true,
      required: true,
      options: statusOptions,
      schema: () => Yup.number().nullable().required("Required"),
    },
    // {
    //   name: "mfa",
    //   label: "Multi-Factor Authentication",
    //   initialValue: mfa,
    //   radio: true,
    //   required: false,
    //   schema: () => null,
    // },
    // {
    //   name: "institutional_admin",
    //   label: "Institutional Admin (This user is not a doctor or a proxy)",
    //   initialValue: false,
    //   radio: true,
    //   required: false,
    //   schema: () => null,
    // },
  ]
    .filter((f) => (userPracticeId ? f.name !== "practice_id" : true))
    .filter((f) => (id ? !["institutional_admin"].includes(f.name) : true))
    .filter((f) => (!isProfile ? !["mfa"].includes(f.name) : true))
    .filter((f) =>
      isProfile ? !["status", "institutional_admin"].includes(f.name) : true
    )
    .filter((f) => (!id || userId == id ? f.name !== "status" : true))
    .filter((f) => (isProfile || id ? f.name !== "phone" : true))
    .map((m) => ({
      ...m,
      readOnly: id && roleInfo.admin_research_fellow ? true : false,
    }));

  return (
    <BasicForm
      error={error}
      fields={fields}
      loading={isLoading}
      onSubmit={onSubmit}
      showButton={!id ? true : !roleInfo.admin_research_fellow ? true : false}
    />
  );
}

function mapStateToProps(state) {
  return {
    userPracticeId: state.user ? state.user.practice_id : null,
    userId: state.user ? state.user.user_id : null,
  };
}

export default connect(mapStateToProps)(UserForm);

UserForm.defaultProps = {
  email: "",
  firstName: "",
  lastName: "",
  mfa: false,
  onSuccess: () => alert("On Success"),
  phone: "",
  practiceId: null,
  practiceUserId: null,
  prefix: "",
  status: "",
  suffix: "",
  userPracticeId: null,
};
