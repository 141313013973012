import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import styled from "styled-components";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import {
  getCoreRowModel,
  useReactTable,
  getFilteredRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import { Form, Modal, Pagination, Checkbox } from "semantic-ui-react";
import formatDistance from "date-fns/formatDistance";

import {
  BasicGrid,
  Card,
  Button,
  Icon,
  JoinLabels,
  EmptyList,
} from "@components/shared";

import { PatientPracticeSelect } from "@components/Patient";

import formatEye from "@util/formatEye";
import { getUuid } from "@util/getUuid";
import { varietyOptions as visitOptions } from "@components/PostOp/postOpOptions";
import { bcvaFormatted } from "./procedureOptions";
import { size } from "@util/breakpoints";

const iconLinkSize = 24;

export default function ProcedureList({
  isAdmin,
  patientId,
  procedures,
  showLink,
  showPatientLink,
  retrievalData,
  fetchDetailData,
}) {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [check, setCheck] = useState(false);
  const [pageCount, setPageCount] = useState(-1);
  const [selectedPracticeId, setSelectedPracticeId] = useState(null);
  const [total, setTotal] = useState(0);
  const [filters, setFilters] = useState({
    search_value: "",
  });

  const table = useReactTable({
    manualPagination: true,
    pageCount,
    data: items,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageSize: 10,
      },
    },
  });

  const CancelToken = axios.CancelToken;
  const cancelRef = useRef(null);

  useEffect(() => {
    if (!retrievalData) return;

    fetchData();
  }, [selectedPracticeId]);

  useEffect(() => {
    if (procedures.length) {
      setItems(procedures);
    }
  }, [JSON.stringify(procedures)]);

  useEffect(() => {
    if (procedures.length) return;
    if (
      filters.search_value.length &&
      table.getState().pagination.pageIndex === 0
    )
      return;
    fetchData();
  }, [JSON.stringify(table.getState().pagination)]);

  useEffect(() => {
    if (procedures.length) return;
    resetTablePage().then(() => fetchData());
  }, [JSON.stringify(filters)]);

  const resetTablePage = () =>
    new Promise((resolve, reject) => {
      resolve(table.setPageIndex(0));
    });

  function fetchData() {
    setLoading(true);

    const cancel = cancelRef.current;
    if (cancel) cancel();

    const req = {
      ...retrievalData.req,
      ...filters,
      page_size: table.getState().pagination.pageSize,
      page:
        table.getState().pagination.pageIndex === -1
          ? 0
          : table.getState().pagination.pageIndex,
      status: 1,
      // variety: 1,
      // search: searchValue,
    };

    if (selectedPracticeId) {
      req.practice_id = selectedPracticeId;
    }

    axios
      .post(retrievalData.url, req, {
        cancelToken: new CancelToken(function executor(c) {
          cancelRef.current = c;
        }),
      })
      .then(({ data }) => {
        setLoading(false);
        setTotal(data.response[0].total);
        setItems(data.response[0].data.map((m) => ({ ...m, uuid: getUuid() })));
        setPageCount(data.response[0].page_count);
        window.scrollTo(0, 0);
      })
      .catch((err) => {
        setLoading(false);
        setItems([]);
      });
  }

  function commitProcedure(procedureId) {
    if (fetchDetailData) {
      axios
        .post(`/procedures/${procedureId}/commit/`, {
          procedure_id: procedureId,
        })
        .then(() => {
          toast.success("Committed!");
          setCheck(false);
          fetchDetailData();
        })
        .catch((err) => {
          toast.error(err);
          setCheck(false);
          fetchDetailData();
        });
    } else {
      axios
        .post(`/procedures/${procedureId}/commit/`, {
          procedure_id: procedureId,
        })
        .then(() => {
          toast.success("Committed!");
          setCheck(false);
          fetchData();
        })
        .catch((err) => {
          toast.error(err);
          setCheck(false);
          fetchData();
        });
    }
  }
  return (
    <React.Fragment>
      {/* {loading && <p>Fetching procedures...</p>} */}
      {patientId && isAdmin && (
        <div style={{ marginBottom: "1em" }}>
          <PatientPracticeSelect
            patientId={patientId}
            value={selectedPracticeId}
            onChange={(e) => setSelectedPracticeId(e)}
            label="Filter procedures by trust"
            showAll
          />
        </div>
      )}
      {!loading && items.length === 0 && (
        <EmptyList text="No procedures yet..." />
      )}
      {!patientId && procedures.length === 0 && (
        <Form style={{ marginBottom: "1em" }}>
          <Form.Input
            value={filters.search_value}
            onChange={(e) =>
              setFilters({
                ...filters,
                search_value: e.target.value,
              })
            }
            placeholder="Search procedures by Patient ID..."
            width={window.innerWidth >= size.laptop ? 4 : 16}
          />
        </Form>
      )}
      <BasicGrid columns={1}>
        {table.getRowModel().rows.map(({ original: item }, i) => (
          <Card.Wrapper key={item.uuid}>
            <Expander
              content={
                <Card.Content>
                  {item.join_names && item.join_names.length > 0 && (
                    <BasicGrid columns={2}>
                      {item.join_names
                        .filter((f) => f.joins.length)
                        .map((m, i) => {
                          const joinLinkUrl = item.committed_to_registry
                            ? null
                            : getJoinLink(item.id, m.title);

                          return (
                            <JoinLabels
                              key={getUuid()}
                              title={m.title}
                              joins={m.joins.map((mm, ii) => ({
                                text: `${mm.text}${
                                  mm.description ? ` ${mm.description}` : ""
                                }`,
                                id: getUuid(),
                                url: joinLinkUrl,
                              }))}
                            />
                          );
                        })}
                    </BasicGrid>
                  )}
                  {item.procedure_comorbidities &&
                    item.procedure_comorbidities.length > 0 && (
                      <BasicGrid columns={2} style={{ marginTop: "0" }}>
                        <JoinLabels
                          title="Comorbidities"
                          joins={item.procedure_comorbidities.map((m) => ({
                            text: `${m.name}${
                              m.description ? ` - ${m.description}` : ""
                            }`,
                            id: m.id,
                            url: item.committed_to_registry
                              ? null
                              : getJoinLink(item.id, "Comorbidities"),
                          }))}
                        />
                      </BasicGrid>
                    )}
                  <VisitsDisplay visits={item.post_op_visits_array || []} />
                </Card.Content>
              }
            >
              <Card.Meta
                timeSince={
                  item.patient_diagnosis_date && item.operation_date
                    ? `${formatDistance(
                        new Date(item.operation_date),
                        new Date(item.patient_diagnosis_date)
                      )} after Glaucoma diagnosis`
                    : null
                }
                isProcedure
                canCommit={item.can_commit}
                isCommitted={item.committed_to_registry}
                hasActions={showLink || showPatientLink}
                description={`${item.formatted_patient_name} - ${
                  item.age
                } year old ${item.ethnicity || ""} ${item.gender}`}
                title={item.operation_date_formatted}
                doctor={item.doctor_name}
                missingFields={item.missing_fields}
                location={
                  isAdmin ? (
                    <Button.Link
                      to={`/practices/${item.practice_id}`}
                      text={item.practice_name}
                    />
                  ) : (
                    item.practice_name
                  )
                }
                stats={[
                  {
                    title: "Eye",
                    value: formatEye(item.eye),
                  },
                  { title: "IOP", value: item.iop ? `${item.iop}mmHg` : "--" },
                  {
                    title: "BCVA",
                    value: bcvaFormatted(item.bcva, item.bcva_method),
                  },
                  // {
                  //   title: "Axial Length",
                  //   value: item.axial_length ? item.axial_length : "--",
                  // },
                  {
                    title: "Power",
                    value: item.power ? `${item.power}mW` : "--",
                  },
                  {
                    title: "Duration",
                    value: item.duration ? `${item.duration}ms` : null,
                  },
                  {
                    title: "Spots",
                    value: item.number_of_spots
                      ? `${item.number_of_spots}`
                      : null,
                  },
                  {
                    title: "Sweep Velocity",
                    value: item.sweep_velocity_formatted
                      ? item.sweep_velocity_formatted
                      : null,
                  },
                  {
                    title: "",
                    value:
                      item.laser_parameter !== 2 &&
                      item.sweep_count_formatted &&
                      item.technique_formatted
                        ? `${item.sweep_count_formatted} sweeps per ${item.technique_formatted}`
                        : null,
                  },
                ].filter((f) => f.value)}
              />
            </Expander>
            <Card.Actions>
              {item.can_commit && !item.committed_to_registry && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "fit-content",
                    margin: "10px auto",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <CommitButton
                      onSubmit={() => commitProcedure(item.id)}
                      procedureId={item.id}
                      item={item}
                    />
                  </div>
                </div>
              )}
              {(showLink || (showPatientLink && item.patient_id)) && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  {showLink && (
                    <Button.Link
                      to={`/procedures/${item.id}`}
                      text={<Icon.Eye size={27} />}
                      title="View Procedure"
                    />
                  )}
                  {showPatientLink && item.patient_id && (
                    <Button.Link
                      to={`/patients/${item.patient_id}`}
                      text={<Icon.User size={27} />}
                      title="View Patient"
                    />
                  )}
                </div>
              )}
            </Card.Actions>
          </Card.Wrapper>
        ))}
      </BasicGrid>
      {procedures.length === 0 && pageCount > 1 && (
        <Pagination
          activePage={table.getState().pagination.pageIndex + 1}
          onPageChange={(e, d) => table.setPageIndex(d.activePage - 1)}
          totalPages={pageCount}
          style={{
            marginTop: "1em",
          }}
        />
      )}
    </React.Fragment>
  );
}

ProcedureList.defaultProps = {
  isAdmin: false,
  procedures: [],
  showLink: true,
  showPatientLink: true,
};

const VisitsWrapper = styled.div`
  display: flex;
  align-items: center;
  text-shadow: 2px 2px 2px #333;

  @media (max-width: ${size.tablet}) {
    display: none;
  }
`;

const VisitsDisplay = ({ visits }) => {
  const visitVarieties = visits.map((v) => v.variety);

  return (
    <VisitsWrapper>
      {visitOptions.map((v, i) => (
        <VisitDisplay
          key={i + 1}
          active={visitVarieties.includes(v.value)}
          visitId={
            visitVarieties.includes(v.value) &&
            visits.find((f) => f.variety === v.value).id
              ? visits.find((f) => f.variety === v.value).id
              : null
          }
          text={v.text
            .replace("Day", "D")
            .replace("Week", "W")
            .replace("Months", "M")
            .replace("Month", "M")
            .replace(" ", "")}
        />
      ))}
    </VisitsWrapper>
  );
};

VisitsDisplay.defaultProps = {
  visits: [],
};

const VisitWrapper = styled.div`
  color: #fff;
  font-weight: bold;
  opacity: ${(props) => (props.active ? 1 : 0.25)};

  a {
    color: #fff;
  }

  & + & {
    margin-left: 10px;
  }
`;

const VisitDisplay = ({ text, active, visitId }) => {
  return (
    <VisitWrapper active={active}>
      {visitId ? <Link to={`/post-op-visits/${visitId}`}>{text}</Link> : text}
    </VisitWrapper>
  );
};

VisitDisplay.defaultProps = {
  text: "",
  active: "",
};

const CommitButton = ({ onSubmit, item }) => {
  const [isOpen, setOpen] = useState(false);

  function onClose() {
    setOpen(false);
  }

  const checks = [
    {
      label: "ocular & systemic comorbidities",
      count: item.procedure_comorbidities
        ? item.procedure_comorbidities.length
        : 0,
    },
    {
      label: "previous ocular surgeries",
      count: item.pos_procedures ? item.pos_procedures.length : 0,
    },
    {
      label: "ocular medications",
      count: item.join_names
        ? item.join_names.find((f) => f.title === "Medications")
          ? item.join_names.find((f) => f.title === "Medications").joins.length
          : 0
        : 0,
    },
  ].filter((f) => f.count === 0);

  return (
    <div>
      <Button.Basic
        text={"Commit to registry"}
        onClick={() => setOpen(true)}
        style={{
          color: "#fff",
          fontWeight: "bold",
          border: "none",
          margin: "0 auto",
          background: "var(--primaryColor)",
        }}
      />
      <Modal open={isOpen} onClose={onClose}>
        <Modal.Content>
          {checks.length > 0 && (
            <div>
              <p>
                Before you commit, please confirm the following are correct:
              </p>
              {checks.map((m, i) => (
                <div style={{ marginBottom: "5px" }} key={i}>
                  <Checkbox
                    label={
                      <label>
                        {m.count === 0 ? (
                          <span>This patient has no {m.label}</span>
                        ) : (
                          <span>
                            {m.label} ({m.count})
                          </span>
                        )}
                      </label>
                    }
                  />
                </div>
              ))}
            </div>
          )}
          <p style={{ marginTop: "1em" }}>
            Are you sure you want to commit this procedure to the registry?
          </p>
        </Modal.Content>
        <Modal.Actions>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Button.Basic
              text="Commit Procedure"
              onClick={() => {
                onSubmit();
                onClose();
              }}
              primary
            />
            <Button.Basic text="Cancel" onClick={() => onClose()} />
          </div>
        </Modal.Actions>
      </Modal>
    </div>
  );
};

function getJoinLink(procedureId, joinName) {
  const base = `/procedures/${procedureId}?edit=true`;
  const HISTORY_VIEW = "history";
  const EXAMINATION_VIEW = "examination";

  switch (joinName) {
    case "Medications":
      return `${base}&view=${HISTORY_VIEW}`;
    case "Glaucoma Diagnoses":
      return `${base}&view=${HISTORY_VIEW}`;
    case "Comorbidities":
      return `${base}&view=${HISTORY_VIEW}`;
    case "Cataract Status":
      return `${base}&view=${EXAMINATION_VIEW}`;
    case "SLE Findings":
      return `${base}&view=${EXAMINATION_VIEW}`;
    case "Gonioscopies":
      return `${base}&view=${EXAMINATION_VIEW}`;
    case "Retinal Findings":
      return `${base}&view=${EXAMINATION_VIEW}`;
    default:
      return null;
  }
}

const Expander = ({ children, content }) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <React.Fragment>
      <div>
        <div>{children}</div>
        {isOpen && <div>{content}</div>}
        <div
          style={{
            padding: "5px 15px",
            background: "var(--primaryColor)",
          }}
        >
          <Button.Basic
            borderless
            text={isOpen ? "see less" : "see more"}
            primary
            onClick={() => setOpen(!isOpen)}
            style={{
              fontSize: "12px",
              padding: "10px",
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );
};
