import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { cssVar, lighten } from "polished";
import { FaUndo } from "react-icons/fa";

import { BasicGrid, Button } from "@components/shared";

export default function AuditList({ items, updateInfo, onUpdate }) {
  return (
    <BasicGrid columns={1}>
      {items.map((item) => (
        <Item
          key={item.uuid}
          author={item.author}
          date={item.created_at}
          entityName={item.medication_name}
          variety={item.variety}
          info={item.info}
          dataState={item.data_state}
          parseInfo={item.parse_info}
          updateInfo={updateInfo}
          onUpdate={() => {
            onUpdate();
            fetchData();
          }}
        />
      ))}
    </BasicGrid>
  );
}

AuditList.defaultProps = {
  updateInfo: null,
  items: [],
};

const ItemBlock = styled.div`
  .meta {
    display: flex;
    background: ${() => cssVar("--primaryColorDark")};
    padding: 5px;
    color: #eee;
    p {
      flex-grow: 1;
      margin: 0;
      font-weight: 600;
      font-size: 0.85em;
    }

    time {
      font-size: 0.75em;
    }
  }

  .changes {
    padding: 10px 5px;
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;
    border-top: 1px solid #eee;
    background-color: ${() => lighten(0.95, "#000")};
  }
`;

function Item({
  author,
  entityName,
  info,
  dataState,
  date,
  parseInfo,
  updateInfo,
  variety,
  onUpdate,
}) {
  return (
    <ItemBlock>
      <div className="changes">
        {parseInfo.length > 0 && (
          <React.Fragment>
            {parseInfo.map((p) => (
              <ChangeWrapper key={p.uuid}>
                {entityName && <b>{entityName} </b>}
                <b>{p.property_formatted}</b>{" "}
                <React.Fragment>
                  {!p.constant_info ? (
                    <React.Fragment>
                      {JSON.stringify(p.initial)} to {JSON.stringify(p.edited)}
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      {p.constant_info[p.initial]} to{" "}
                      {p.constant_info[p.edited]}
                    </React.Fragment>
                  )}
                </React.Fragment>
              </ChangeWrapper>
            ))}
          </React.Fragment>
        )}
        {parseInfo.length === 0 && <p>Information unavailable</p>}
      </div>
      <div className="meta">
        {author && (
          <p>
            {[0, 1].includes(variety) && (
              <React.Fragment>
                {variety === 0 && "Created by "}
                {variety === 1 && "Updated by "}
              </React.Fragment>
            )}
            {author}
          </p>
        )}{" "}
        {/* {updateInfo && (
          <div style={{ marginRight: "8px" }}>
            <UndoButton
              updateInfo={{
                ...updateInfo,
                req: {
                  ...updateInfo.req,
                  ...parseInfo.reduce((acc, cur) => {
                    acc[cur.property] = cur.initial;
                    return acc;
                  }, {}),
                },
              }}
              onSuccess={onUpdate}
            />
          </div>
        )} */}
        {date && <time>{new Date(date).toLocaleString()}</time>}
      </div>
    </ItemBlock>
  );
}

const UndoButton = ({ updateInfo, onSuccess }) => {
  const [loading, setLoading] = useState(false);

  function onClick() {
    setLoading(true);

    const req = {
      ...updateInfo.req,
    };

    updateInfo
      .xhr(req)
      .then(() => {
        onSuccess();
      })
      .catch((err) => {});
  }

  return (
    <Button.Icon
      icon={<FaUndo color="#fff" />}
      disabled={loading}
      onClick={onClick}
    />
  );
};

Item.defaultProps = {
  author: null,
  date: null,
  info: null,
  dataState: null,
  parseInfo: [],
};

const ChangeWrapper = styled.div`
  b {
    margin-right: 20px;
  }
`;
