import React from "react";
import styled from "styled-components";
import { cssVar, lighten } from "polished";
import { Popup } from "semantic-ui-react";

import { Icon } from "@components/shared";
import { getUuid } from "@util/getUuid";
import { size, device } from "@util/breakpoints";
import {
  FaRegCheckCircle,
  FaExclamationCircle,
  FaQuestionCircle,
} from "react-icons/fa";
const borderRadius = `8px`;

const Card = styled.div`
  position: relative;
  max-width: 100%;
  /* overflow: hidden; */
`;

export function Wrapper({ children }) {
  return <Card>{children}</Card>;
}

const MetaWrapper = styled.div`
  background: ${(props) =>
    props.tertiary
      ? "var(--negativeColor)"
      : props.secondary
      ? "var(--secondaryColorDark)"
      : "var(--primaryColor)"};
  padding: ${(props) => (props.mini ? "10px 8px" : "15px 12px")};
  border-radius: ${(props) =>
    `${borderRadius} ${borderRadius} ${
      props.hasActions ? "0 0" : `${borderRadius} ${borderRadius}`
    }`};

  .meta-header {
    display: flex;
    align-items: center;
    text-shadow: 1px 1px 1px #222;
  }

  .description {
    // margin-bottom: 0.5em;
    font-size: 1.15em;
    text-shadow: 1px 1px 1px #222;
    margin-bottom: 1em;
  }

  .status {
    position: absolute;
    top: 15px;
    right: 25px;
    font-weight: 550;
    font-size: 0.75em;
    text-shadow: 1px 1px 1px #444;
    display: flex;
    align-items: center;
    color: #fff;

    svg {
      font-size: 1.3em;
      margin-right: 5px;
    }

    &.committed {
    }

    &.can-commit {
    }

    &.incomplete {
      color: #fff;
      opacity: 0.4;
      cursor: pointer;

      svg {
        fill: #fff;
      }
    }
  }

  .location,
  .doctor {
    text-shadow: 1px 1px 1px #222;
    color: #eee;
    font-weight: bold;
    font-size: 0.85em;
    display: flex;
    align-items: center;
    opacity: 0.95;
    margin-left: 2em;

    svg {
      margin-right: 5px;
    }

    a {
      color: #fff;
    }
  }

  h1 {
    /* flex-grow: 1; */
    margin-bottom: 0 !important;
    display: flex;
    flex-direction: column;
    font-size: ${(props) => (props.mini ? "1.35em" : "1.75rem")};
    line-height: 1.25;
    color: #fff;
    text-shadow: 1px 1px 2px #222;
    // font-weight: normal;
    margin-bottom: 8px;

    span {
      font-weight: 400;
      font-size: 14px;
    }
  }

  p {
    text-shadow: 1px 1px 2px #222;
    color: #fff;
    opacity: 0.85;
    margin: 0 0 0 0;
  }
`;

export const Meta = ({
  mini,
  location,
  title,
  doctor,
  secondary,
  tertiary,
  stats,
  description,
  hasActions,
  labels,
  isCommitted,
  canCommit,
  isProcedure,
  timeSince,
  missingFields,
}) => (
  <MetaWrapper
    hasActions={hasActions}
    mini={mini}
    secondary={secondary}
    tertiary={tertiary}
  >
    <div className="meta-header">
      <h1>{title}</h1>
      {location && (
        <div className="location">
          <Icon.Practice />
          {location}
        </div>
      )}
      {doctor && (
        <div className="doctor">
          <Icon.User />
          {doctor}
        </div>
      )}
    </div>
    {isProcedure && timeSince && <p>{timeSince}</p>}
    {description && <p className="description">{description}</p>}
    {isProcedure && (
      <div>
        {!isCommitted && !canCommit && (
          <div>
            <Popup
              content={
                missingFields.length > 0 ? (
                  <div className="procedure-missing">
                    <p style={{ marginBottom: "0" }}>Missing:</p>
                    <ul style={{ margin: "0", padding: "0 15px" }}>
                      {missingFields.map((m, i) => (
                        <li key={i}>{m}</li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  "Please check detail"
                )
              }
              trigger={
                <p className="status incomplete">
                  <FaQuestionCircle /> Incomplete
                </p>
              }
            />
          </div>
        )}
        {isCommitted && (
          <p className="status committed">
            <FaRegCheckCircle /> Committed
          </p>
        )}
        {!isCommitted && canCommit && (
          <p className="status can-commit">
            <FaExclamationCircle /> Ready to commit
          </p>
        )}
      </div>
    )}
    {stats.length > 0 && <Stats stats={stats} />}
    {labels.length > 0 && <Labels tertiary={tertiary} labels={labels} />}
  </MetaWrapper>
);

Meta.defaultProps = {
  location: null,
  mini: false,
  hasActions: true,
  stats: [],
  title: "",
  labels: [],
  description: "",
  tertiary: false,
  missingFields: [],
};

const StyledLabels = styled.div`
  margin: 10px 0;
  display: flex;
  align-items: center;
  text-shadow: 1px 1px 1px #333;

  div {
    background: ${(props) =>
      !props.tertiary
        ? `var(--primaryColorDark)`
        : "var(--negativeColorLight)"};
    color: ${(props) =>
      !props.tertiary ? "var(--primaryColorLight)" : "var(--negativeColor)"};
    border-radius: 5px;
    padding: 2px 8px;
    color: #eee;
    font-size: 0.85em;
    margin-right: 5px;
  }
`;

const Labels = ({ labels, tertiary }) => {
  return (
    <StyledLabels tertiary={tertiary}>
      {labels.map((m, i) => (
        <div key={i}>{m.text}</div>
      ))}
    </StyledLabels>
  );
};

StyledLabels.defaultProps = {
  tertiary: false,
};

const ContentWrapper = styled.div`
  background: ${(props) =>
    props.secondary ? "var(--secondaryColorDark)" : "var(--primaryColor)"};
  padding: 0 12px 10px 12px;
  border-radius: ${(props) =>
    props.rounded ? `0 0 ${borderRadius} ${borderRadius}` : 0};
`;

export const Content = ({ children, secondary, rounded }) => {
  return (
    <ContentWrapper rounded={rounded} secondary={secondary}>
      {children}
    </ContentWrapper>
  );
};

const StatsWrapper = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  margin-top: 5px;
  text-shadow: 1px 1px 1px #333;

  @media (max-width: ${size.tablet}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Stats = ({ stats }) => {
  return (
    <StatsWrapper>
      {stats.map((s, i) => (
        <StatBlock key={getUuid()} title={s.title} value={s.value} />
      ))}
    </StatsWrapper>
  );
};

const StatBlockWrapper = styled.div`
  display: flex;
  /* flex-direction: column; */
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  color: #eee;

  & + & {
    /* margin-left: 12px; */

    @media (max-width: ${size.tablet}) {
      margin-left: 0;
    }
  }

  span {
    font-size: 12px;
    font-weight: 400;
    margin-left: 5px;
    color: ${() => lighten(0.9, "#fff")};
    opacity: 0.75;
  }
`;

const StatBlock = ({ title, value }) => (
  <StatBlockWrapper>
    {value}
    <span>{title}</span>
  </StatBlockWrapper>
);

const ActionsWrapper = styled.div`
  padding: 5px;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: ${(props) =>
    props.tertiary
      ? "var(--negativeColorLight)"
      : props.secondary
      ? "var(--secondaryColorLight)"
      : "var(--primaryColorLight)"};
  border-radius: 0 0 ${borderRadius} ${borderRadius};

  a {
    color: #fff;

    & + a {
      margin-left: 8px;
    }
  }
`;

export const Actions = ({ children, secondary, tertiary }) => (
  <ActionsWrapper secondary={secondary} tertiary={tertiary}>
    {children}
  </ActionsWrapper>
);

Actions.defaultProps = {
  secondary: false,
};
