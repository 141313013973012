import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import styled from "styled-components";

import { Wrapped } from "@components/Layouts";
import { formatProcedureJoins } from "@components/Procedure/ProcedureJoinManagement";
import {
  BasicGrid,
  Button,
  Card,
  Icon,
  EmptyList,
  ToggleTitle,
} from "@components/shared";

import { getUuid } from "@util/getUuid";
import formatEye from "@util/formatEye";

export default function PatientHistory({ patientId }) {
  const items = [...formatProcedureJoins(patientId)]
    .filter((f) => !["PostOpComplication"].includes(f.entity))
    .map((m) => ({
      // ...m,
      procedure_association_group: m.procedure_association_group,
      key: m.key,
      title: m.title,
      entity: m.patient_entity,
      variety:
        m.retrievalData && m.retrievalData.req
          ? m.retrievalData.req.variety
          : null,
    }))
    .map((m) => ({
      ...m,
      variety: m.variety || null,
    }))
    .map((m) => ({
      ...m,
      is_glaucoma_diagnosis: m.title === "Glaucoma Diagnoses",
      is_pos: m.title === "Previous Ocular Surgery",
      request_data: {
        patient_id: patientId,
        variety: m.variety,
        scope: m.entity,
      },
    }));

  return (
    <Wrapped>
      <h1 style={{ marginBottom: "1em" }}>History</h1>
      {items
        .filter((f) => f.procedure_association_group === "history")
        .map((m) => (
          <Item
            key={m.key}
            isGlaucomaDiagnosis={m.is_glaucoma_diagnosis}
            isPos={m.is_pos}
            patientId={patientId}
            requestData={m.request_data}
            title={m.title}
          />
        ))}
      <h1 style={{ marginBottom: "1em" }}>Clinical background</h1>
      {items
        .filter((f) => f.procedure_association_group === "examination")
        .map((m) => (
          <Item
            key={m.key}
            isGlaucomaDiagnosis={m.is_glaucoma_diagnosis}
            isPos={m.is_pos}
            patientId={patientId}
            requestData={m.request_data}
            title={m.title}
          />
        ))}
    </Wrapped>
  );
}

const StyledItem = styled.div`
  padding: ${(props) => (props.secondary ? "1.5em 0" : "2em 0")};

  &:first-of-type {
    padding-top: ${(props) => (props.secondary ? "1.5em" : 0)};
  }

  & + & {
    border-top: 1px solid #eee;
  }

  .items {
    margin-left: 1.5em;
  }
`;

const Item = ({
  isGlaucomaDiagnosis,
  isPos,
  patientId,
  requestData,
  title,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [init, setInit] = useState(false);

  useEffect(() => {
    if (!isOpen) return;

    fetchData();
  }, [isOpen]);

  function fetchData() {
    setLoading(true);

    const req = { ...requestData };

    axios
      .post(`/patients/${patientId}/retrieve-history/`, req)
      .then(({ data }) => {
        setLoading(false);
        setItems(data.response);
        setInit(true);
      })
      .catch((err) => {
        setLoading(false);
        setItems([]);
      });
  }

  return (
    <StyledItem>
      <ToggleTitle
        title={title}
        onClick={() => setOpen(!isOpen)}
        isOpen={isOpen}
        loading={loading}
      />
      {isOpen && (
        <React.Fragment>
          <div className="items">
            <React.Fragment>
              {!isPos && !isGlaucomaDiagnosis && (
                <React.Fragment>
                  {items.map((item) => (
                    <BreakdownItem
                      key={item.id}
                      title={item.name}
                      id={item.id}
                      patientId={patientId}
                      requestData={{
                        ...requestData,
                        id: item.id,
                      }}
                    />
                  ))}
                </React.Fragment>
              )}
              {(isPos || isGlaucomaDiagnosis) && (
                <NonInteractiveItems items={items} />
              )}
              {!loading && items.length === 0 && init && (
                <EmptyList text={`No ${title} yet...`} />
              )}
            </React.Fragment>
          </div>
        </React.Fragment>
      )}
    </StyledItem>
  );
};

Item.defaultProps = {
  isGlaucomaDiagnosis: false,
  isPos: false,
};

const BreakdownItem = ({ title, id, patientId, requestData }) => {
  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (!isOpen) return;

    fetchData();
  }, [isOpen]);

  function fetchData() {
    if (items.length) return;

    setLoading(true);

    const req = { ...requestData };

    axios
      .post(`/patients/${patientId}/history-breakdown/`, req)
      .then(({ data }) => {
        setLoading(false);
        setItems(
          data.response
            .map((m) => ({
              ...m,
              uuid: getUuid(),
            }))
            .sort((a, b) =>
              new Date(a.operation_date) > new Date(b.operation_date) ? 1 : -1
            )
        );
      })
      .catch((err) => {
        setLoading(false);
        setItems([]);
      });
  }

  return (
    <StyledItem secondary>
      <ToggleTitle
        title={title}
        onClick={() => setOpen(!isOpen)}
        isOpen={isOpen}
        secondary
        loading={loading}
      />
      {isOpen && (
        <BasicGrid columns={3}>
          {items.map((item) => (
            <Card.Wrapper key={item.uuid}>
              <Card.Meta
                mini
                secondary={item.post_op_visit_id ? true : false}
                // title={item.operation_date_formatted}
                title={
                  item.variety
                    ? `${item.variety} Visit - ${item.operation_date_formatted}`
                    : `${formatEye(item.eye)} Procedure - ${
                        item.operation_date_formatted
                      }`
                }
                // title={
                //   <React.Fragment>
                //     {item.operation_date_formatted
                //       ? item.operation_date_formatted
                //       : "unknown..."}
                //     {/* {item.eye && (
                //       <React.Fragment> - {formatEye(item.eye)}</React.Fragment>
                //     )} */}
                //   </React.Fragment>
                // }
                // title={item.operation_date || `${item.variety} Visit`}
                description={
                  <div>
                    {item.variety
                      ? `recorded during Post-Op Visit for ${formatEye(
                          item.eye
                        )} Procedure`
                      : `recorded during ${formatEye(item.eye)} Procedure`}
                    {item.frequency_formatted && (
                      <p>{item.frequency_formatted}</p>
                    )}
                  </div>
                }
              />
              <Card.Actions secondary={item.post_op_visit_id ? true : false}>
                <Button.Link
                  text={<Icon.Eye size={18} />}
                  to={
                    item.post_op_visit_id
                      ? `/post-op-visits/${item.post_op_visit_id}`
                      : `/procedures/${item.procedure_id}`
                  }
                />
              </Card.Actions>
            </Card.Wrapper>
          ))}
        </BasicGrid>
      )}
    </StyledItem>
  );
};

const NonInteractiveItems = ({ items }) => {
  return (
    <BasicGrid columns={3}>
      {items.map((item) => (
        <Card.Wrapper key={item.uuid}>
          <Card.Meta
            hasActions={false}
            mini
            title={item.name}
            description={item.date_formatted}
          />
        </Card.Wrapper>
      ))}
    </BasicGrid>
  );
};

NonInteractiveItems.defaultProps = {
  items: [],
};
