import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form } from "semantic-ui-react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

import { getRoleInfo } from "@util/roleInfo";
import { Wrapped } from "@components/Layouts";
import {
  BasicGrid,
  Button,
  Card,
  DateField,
  EmptyList,
} from "@components/shared";
import { ComplicationResolution, ComplicationEdit } from "./";

export default function ComplicationManagement({
  minDate,
  procedureId,
  postopId,
}) {
  const [complications, setComplications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isOpen, setOpen] = useState(false);

  const roleInfo = getRoleInfo(useSelector((state) => state));

  useEffect(() => {
    fetchComplications();
  }, []);

  function fetchComplications() {
    axios
      .get(
        procedureId
          ? `/procedures/${procedureId}/complications/`
          : `/post-op-visits/${postopId}/complications/`
      )
      .then(({ data }) => {
        setComplications(data.response);
        setLoading(false);
      })
      .catch((err) => {
        setComplications([]);
        setLoading(false);
      });
  }

  return (
    <Wrapped
      button={
        roleInfo.user_doctor ||
        roleInfo.user_proxy ||
        roleInfo.user_inst_admin ||
        roleInfo.user_originator ? (
          <Button.Toggle
            closedText="Add Visit"
            onClick={() => setOpen(!isOpen)}
            open={isOpen}
          />
        ) : null
      }
      title="Complications"
    >
      {!isOpen && (
        <React.Fragment>
          {complications.length === 0 && !loading && (
            <div style={{ marginBottom: "1em" }}>
              <EmptyList
                text={`This ${
                  procedureId ? "procedure" : "post-op visit"
                } has no complications`}
              />
            </div>
          )}
          {complications.length > 0 && (
            <div style={{ marginBottom: "1em" }}>
              <BasicGrid columns={2}>
                {complications.map((c) => (
                  <Card.Wrapper key={c.id}>
                    <Card.Meta
                      hasActions={
                        !c.resolved &&
                        (roleInfo.user_doctor || roleInfo.user_proxy)
                      }
                      tertiary
                      title={c.name}
                      description={`${
                        c.device_related ? "Device Related, " : ""
                      }${c.description}${
                        c.resolved
                          ? ` - resolved ${c.resolution_date_formatted}${
                              c.resolution_note ? `, ${c.resolution_note}` : ""
                            }`
                          : ""
                      }`}
                      stats={
                        c.date_formatted
                          ? [
                              {
                                title: "",
                                value: c.date_formatted,
                              },
                            ]
                          : []
                      }
                    />
                    <Card.Actions tertiary>
                      <div
                        style={{
                          marginRight: "5px",
                        }}
                      >
                        <ComplicationEdit
                          editData={c}
                          procedureId={procedureId}
                          postopId={postopId}
                          fetchData={fetchComplications}
                        />
                      </div>
                      {!c.resolved &&
                        (roleInfo.user_doctor || roleInfo.user_proxy) && (
                          <ComplicationResolution
                            minDate={c.date}
                            fetchData={fetchComplications}
                            joinId={c.join_id}
                            procedureId={procedureId}
                            postopId={postopId}
                          />
                        )}
                    </Card.Actions>
                  </Card.Wrapper>
                ))}
              </BasicGrid>
            </div>
          )}
        </React.Fragment>
      )}
      {isOpen &&
        (roleInfo.user_doctor ||
          roleInfo.user_proxy ||
          roleInfo.user_inst_admin ||
          roleInfo.user_originator) && (
          <ComplicationForm
            minDate={minDate}
            reqData={{
              procedure_id: procedureId,
              post_op_id: postopId,
            }}
            onSuccess={() => {
              fetchComplications();
              setOpen(false);
            }}
            createRoute={
              procedureId
                ? `/procedures/${procedureId}/add-complication/`
                : `/post-op-visits/${postopId}/add-complication/`
            }
          />
        )}
    </Wrapped>
  );
}

ComplicationManagement.defaultProps = {
  minDate: null,
  procedureId: null,
  postopId: null,
};

export const ComplicationForm = ({
  minDate,
  reqData,
  onSuccess,
  createRoute,
  editData,
  updateRoute,
}) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    date: null,
    device_related: false,
    description: "",
    id: null,
  });

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (editData) {
      setFormData({
        date: editData.date ? new Date(editData.date) : null,
        device_related: editData.device_related,
        description: editData.description,
        id: editData.complication_id,
      });
    }
  }, [JSON.stringify(editData)]);

  function fetchData() {
    axios
      .post(`/retrieve-complications/`, { status: 1 })
      .then(({ data }) => {
        setOptions(
          data.response
            .map((m) => ({
              key: m.id,
              value: m.id,
              text: m.name,
            }))
            .sort((a, b) => (a.text > b.text ? 1 : -1))
        );
      })
      .catch((err) => {
        setOptions([]);
      });
  }

  function onSubmit() {
    setLoading(true);

    const req = {
      ...reqData,
      ...formData,
    };

    if (editData) return onUpdate(req);

    axios
      .post(createRoute, req)
      .then(() => {
        setLoading(false);
        setFormData({
          device_related: false,
          description: "",
          id: null,
        });
        toast.success("Complication Added!");
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  function onUpdate(req) {
    axios
      .post(updateRoute, {
        ...req,
        id: editData.join_id,
      })
      .then(() => {
        setLoading(false);
        setFormData({
          device_related: false,
          description: "",
          id: null,
        });
        toast.success("Complication Updated!");
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <React.Fragment>
      <Form>
        {!editData && (
          <Form.Select
            label="Complication"
            search
            required
            placeholder="Select one..."
            value={formData.id}
            options={[{ key: 99434, value: null, text: "None" }, ...options]}
            onChange={(e, d) =>
              setFormData({
                ...formData,
                id: d.value,
              })
            }
          />
        )}
        <Form.TextArea
          label="Description"
          value={formData.description}
          required
          onChange={(e) =>
            setFormData({
              ...formData,
              description: e.target.value,
            })
          }
        />
        <Form.Field required>
          <label>Date of Complication</label>
          <DateField
            minDate={minDate ? new Date(minDate) : null}
            selected={formData.date}
            onChange={(e) =>
              setFormData({
                ...formData,
                date: e,
              })
            }
            maxDate={new Date()}
          />
        </Form.Field>
        <Form.Radio
          label={`Device Related`}
          toggle
          checked={formData.device_related}
          onChange={(e) =>
            setFormData({
              ...formData,
              device_related: !formData.device_related,
            })
          }
        />
        <Button.Basic
          text="Submit"
          primary
          onClick={onSubmit}
          loading={loading}
          disabled={
            loading || !formData.id || !formData.description || !formData.date
          }
        />
      </Form>
    </React.Fragment>
  );
};

ComplicationForm.defaultProps = {
  minDate: null,
};
