export const medFrequencyOptions = [
  { value: 9, text: "QHS" },
  { value: 1, text: "QD" },
  { value: 2, text: "BID" },
  { value: 3, text: "TID" },
  { value: 4, text: "QID" },
  { value: 5, text: "Q3 Hours" },
  { value: 6, text: "Q2 Hours" },
  { value: 7, text: "Q1 Hours" },
  { value: 8, text: "PO" },
];

export const statusOptions = [
  { value: 1, text: "active" },
  { value: 2, text: "inactive" },
  { value: 3, text: "deleted" },
];

export const bcvaMethodOptions = [
  { value: 1, text: "Snellen US" },
  { value: 2, text: "Snellen Metric" },
  { value: 3, text: "LogMAR" },
];

export const lensOptions = [
  { value: 1, text: "Phakic" },
  { value: 2, text: "Psuedophakic" },
  { value: 3, text: "Aphakic" },
];

export const bcvaOptions = (method) =>
  [
    { value: 1, logmar: "-0.2", snellen: "20/10", snellen_metric: "6/4" },
    { value: 2, logmar: "-0.1", snellen: "20/15", snellen_metric: "6/5" },
    { value: 3, logmar: "0", snellen: "20/25", snellen_metric: "6/6" },
    { value: 4, logmar: "0.1", snellen: "20/30", snellen_metric: "6/7.5" },
    { value: 5, logmar: "0.2", snellen: "20/40", snellen_metric: "6/9" },
    { value: 6, logmar: "0.3", snellen: "20/50", snellen_metric: "6/12" },
    { value: 7, logmar: "0.4", snellen: "20/60", snellen_metric: "6/18" },
    { value: 8, logmar: "0.5", snellen: "20/70", snellen_metric: "6/24" },
    { value: 9, logmar: "0.6", snellen: "20/80", snellen_metric: "6/36" },
    { value: 10, logmar: "0.7", snellen: "20/100", snellen_metric: "6/60" },
    { value: 11, logmar: "0.8", snellen: "20/200", snellen_metric: "3/60" },
    { value: 12, logmar: "0.9", snellen: "20/300", snellen_metric: "1/60" },
    { value: 13, logmar: "1.0", snellen: "20/400", snellen_metric: "CF" },
    { value: 14, logmar: "1.1", snellen: "CF", snellen_metric: "HM" },
    { value: 15, logmar: "1.2", snellen: "HM", snellen_metric: "PL" },
    { value: 16, logmar: "1.3", snellen: "LP", snellen_metric: "NPL" },
    { value: 17, logmar: "1.4", snellen: "NPL" },
    { value: 18, logmar: "1.5" },
    { value: 19, logmar: "1.6" },
    { value: 20, logmar: "CF" },
    { value: 21, logmar: "HM" },
    { value: 22, logmar: "PL" },
    { value: 23, logmar: "NPL" },
  ]
    .filter((f) => (!method ? false : true))
    .map((m) => ({
      key: m.value,
      value: m.value,
      text:
        method === 1 ? m.snellen : method === 2 ? m.snellen_metric : m.logmar,
    }))
    .filter((f) => f.text);

export function bcvaFormatted(bcvaValue, methodValue) {
  const obj = bcvaOptions(methodValue).find((f) => f.value === bcvaValue);
  return obj ? obj.text : bcvaValue;
}

export const powerOptions = [
  { text: "2000mW", value: 2000 },
  { text: "2250mW", value: 2250 },
  { text: "2500mW", value: 2500 },
  { text: "2750mW", value: 2750 },
  { text: "3000mW", value: 3000 },
];

export const techniqueOptions = [
  { text: "Hemisphere", value: 1, sections: 2 },
  { text: "Quadrant", value: 2, sections: 4 },
  { text: "Discrete Spot", value: 3, sections: null },
];

export const sweepVelocityOptions = [
  { text: "10 seconds per sweep", value: 1 },
  { text: "15 seconds per sweep", value: 2 },
  { text: "20 seconds per sweep", value: 3 },
  { text: "25 seconds per sweep", value: 4 },
  { text: "30 seconds per sweep", value: 5 },
  { text: "35 seconds per sweep", value: 6 },
  { text: "40 seconds per sweep", value: 7 },
  { text: "Discrete Spot", value: 8 },
];

export const sweepCountOptions = [
  { text: "1", value: 1 },
  { text: "2", value: 2 },
  { text: "3", value: 3 },
  { text: "4", value: 4 },
  { text: "5", value: 5 },
  { text: "6", value: 6 },
  { text: "7", value: 7 },
  { text: "8", value: 8 },
  { text: "9", value: 9 },
  { text: "10", value: 10 },
  { text: "Discrete Spot", value: 11 },
];

export const iopVarietyOptions = [
  { text: "Goldmann", value: 1 },
  { text: "Tonopen", value: 2 },
  { text: "Rebound Tonometry", value: 3 },
  { text: "Pnuemo-Tonometry", value: 4 },
];

function generateOptions(min, max, step) {
  let arr = [];

  for (let i = min; i <= max; i += step) {
    arr.push(i);
  }

  return arr;
}

export const wavePowerOptions = generateOptions(1000, 3000, 50).map((m) => ({
  key: m,
  value: m,
  text: `${m}mW`,
}));
export const waveDurationOptions = generateOptions(1000, 20000, 50).map(
  (m) => ({
    key: m,
    value: m,
    text: `${m}ms`,
  })
);
export const waveSpotOptions = generateOptions(1, 30, 1).map((m) => ({
  key: m,
  value: m,
  text: `${m}`,
}));

export const consciousOptions = [
  { text: "Light sedation", value: 1 },
  { text: "Heavy sedation", value: 2 },
  { text: "General", value: 3 },
  { text: "No sedation", value: 4 },
];

export const cdRatioOptions = [
  { text: "1.0", value: 1.0 },
  { text: "0.9", value: 0.9 },
  { text: "0.8", value: 0.8 },
  { text: "0.7", value: 0.7 },
  { text: "0.6", value: 0.6 },
  { text: "0.5", value: 0.5 },
  { text: "0.4", value: 0.4 },
  { text: "0.3", value: 0.3 },
  { text: "0.2", value: 0.2 },
  { text: "0.1", value: 0.1 },
];
